import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ListCommerceService } from '../../services/list-commerce.service';  

@Component({
  selector: 'app-sitio',
  templateUrl: './sitio.component.html',
  styleUrls: ['./sitio.component.css']
})
export class SitioComponent implements OnInit {
  product_id: string; 
  commerce;  

  constructor(private actRoute: ActivatedRoute, public ListService: ListCommerceService) {  
  }

  ngOnInit() {  
    this.actRoute.paramMap.subscribe(params => {  
      //FUNCION PARA MOSTRAR LOS SITIOS DE UNA CIUDAD PREDETERMINADA
      this.ListService.getAllGaleria().subscribe(commerce =>{
        this.commerce = commerce;   
      }); 
    });
  }

} 