import { Component } from '@angular/core';  
import {TranslateService} from '@ngx-translate/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'toursPijao'; 

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  
  constructor(private translate: TranslateService, private router: Router, private location: Location) {
  }
  ngOnInit(): void {
    this.location.subscribe((ev:PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev:any) => {
        if (ev instanceof NavigationStart) {
            if (ev.url != this.lastPoppedUrl)
                this.yScrollStack.push(window.scrollY);
        } else if (ev instanceof NavigationEnd) {
            if (ev.url == this.lastPoppedUrl) {
                this.lastPoppedUrl = undefined;
                window.scrollTo(0, this.yScrollStack.pop());
            } else
                window.scrollTo(0, 0);
        }
    });
    if(sessionStorage.getItem("language") == null){
      sessionStorage.setItem("language", "es");
    }
    if(sessionStorage.getItem("language") == 'es'){
      document.getElementById('id_flag_languages').innerHTML="<img src='assets/img/flag1.webp' height='30px' class='circle' title='language'>";
    }
    else{
      document.getElementById('id_flag_languages').innerHTML="<img src='assets/img/flag2.webp' height='30px' class='circle' title='language'>";
    }
    this.translate.setDefaultLang(sessionStorage.getItem("language"));
    this.translate.use(sessionStorage.getItem("language"));
  } 
}
