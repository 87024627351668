
<section class="col-xs-12 center remove-padding"
style="background: url('../../../assets/img/banner_3.webp') no-repeat;background-size: cover;">
    <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
    <div class="col-md-8 col-md-offset-2 push-150-top push-100-bottom text-shadow text-white">
        <h1 class="font-s48 wow zoomIn">Pijao Quindío, Colombia</h1>
    </div>
</section>
<section class="col-xs-12 bg_black remove-padding">
    <div class="col-xs-12 heightx100 opacity-5 bg-black absolute"></div>
    <div class="col-xs-12">
        <div class="col-md-10 col-md-offset-1 push-50-top push-50-bottom">
            <div class="col-md-6">
            <img src="assets/img/banner_pijao.webp" class="col-xs-12">
            </div>
            <div class="col-md-6 center">
            <div class="col-xs-12 text-white remove-padding"><h2 class="text-shadow">Pijao Quindío</h2>
                <div class="text-left">
                    <p class="font-s18 push-30-top">
                        {{ 'text_pijao_1' | translate }}
                    </p>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>
<style>
.text-vertical {
transform: rotate(-90deg);
}
</style>
<section class="col-xs-12 remove-padding relative">
<div class="col-sm-3 hidden-xs heightx100 absolute remove-padding text-white text-shadow bg_green">
    <div class="col-xs-12 heightx100 bg-black absolute opacity-5"></div>
    <div class="col-xs-12 hidden-md">
        <h2 class="text-vertical pull-right push-150-top font-s36">
            {{ 'title_pijao_1' | translate }}
        </h2>
    </div>
</div>
<div class="col-sm-9 col-sm-offset-3 padding-40">
<div class="col-xs-12">
   <p>
           {{ 'text_pijao_2' | translate }}
   </p>
</div>
<div class="row">
    <div class="col-md-6 push-15-top">
        <span class="fa fa-list-alt font-s24"></span>
        <p>
        <h3>{{ 'title_pijao_3' | translate }}</h3> {{ 'text_pijao_3' | translate }}
    </div>
    <div class="col-md-6 push-15-top">
        <span class="fa fa-circle font-s24"></span>
        <p>
        <h3>{{ 'title_pijao_4' | translate }}</h3>
        {{ 'text_pijao_4' | translate }}
    </div>
</div>
<div class="col-md-6 push-15-top">
   <span class="fa fa-arrow-left font-s24"></span>
   <p>
       <h3>{{ 'title_pijao_5' | translate }}</h3>
       {{ 'text_pijao_5' | translate }}
</div>
<div class="col-md-6 push-15-top">
   <span class="fa fa-battery-4 font-s24"></span>
   <p>
       <h3>{{ 'title_pijao_6' | translate }}</h3> {{ 'text_pijao_6' | translate }}
</div>
</div>
</section>