
<header class="col-xs-12 fixed header-fixed remove-padding index50 hidden-scroll nav-down hidden-xs hidden-sm text-white">
    <div class="col-xs-12 heightx100 bg-black absolute opacity-3"></div>
    <div class="col-md-8 col-md-offset-2 remove-padding">
        <a routerLink="" class="col-md-2 remove-padding">
            <img src="assets/img/logo_blanco_birding.webp" class="height-50 push-5-top push-10-bottom" title="logo_blanco_birding"
                 alt="logo_blanco_birding">
        </a>
        <div class="col-md-10 pull-right h4 push-20-top">
            <a href="#" onclick="changeLanguage();" id="id_flag_languages"><img src="https://elportaldeltemplo.files.wordpress.com/2016/03/bandera-english.webp?w=512" alt="English flag"></a>
            <a routerLink="" class="pull-right push-30-left">
                {{ 'inicio' | translate }}
            </a>
            <a routerLink="pijao" class="pull-right push-30-left"> <span class="hidden-xs hidden-sm">Pijao</span></a>
            <a routerLink="paquetes" class="pull-right push-30-left"> <span class="hidden-xs hidden-sm">{{ 'paquetes' | translate }}</span></a>
            <a routerLink="galeria" class="pull-right push-30-left"> <span class="hidden-xs hidden-sm">{{ 'galeria' | translate }}</span></a>
            <a routerLink="publicaciones" class="pull-right push-30-left"> <span
                    class="hidden-xs hidden-sm"> {{ 'publicaciones' | translate }} </span></a>
        </div>
    </div>
</header>
<div class="col-xs-12 fixed index30 remove-padding hidden-md hidden-lg">
    <div class="col-xs-12 bg-black shadow-3-light padding-10">
        <div class="col-xs-4 text-left">
            <a routerLink=""><img src="assets/img/logo_png.webp" alt="Bio Onda"
                                                  title="Bio Onda" class="col-xs-12 remove-padding"></a>
        </div>
        <a class="col-xs-8 text-right text-white padding-10" href="javascript:void(0);"
           onclick="$('#header_hidden').addClass('slideInRight').removeClass('hidden slideOutRight');$('#backblack').removeClass('hidden');">
            <span class="fa fa-navicon"></span>
        </a>
    </div>
</div>

<div class="col-xs-12 heightx100 opacity-5 bg-black fixed hidden animated index30" id="backblack"
     onclick="$('#backblack').addClass('hidden');$('#header_hidden').removeClass('slideInRight').addClass('slideOutRight');"></div>
<div class="col-xs-8 fixed pull-right right shadow-4 hidden overflow-hidden animated bg-black text-white index30 heightx100 overflow-scroll padding-20"
     id="header_hidden">
    <div class="col-xs-12">
        <img src="assets/img/logo_blanco_birding.webp" alt="Logo_pelis_day" title="Logo pelis day"
             class="col-xs-10 remove-padding">
        <span class="fa fa-remove font-s24 absolute right"
              onclick="$('#backblack').addClass('hidden');$('#header_hidden').removeClass('slideInRight').addClass('slideOutRight');"></span>
    </div>
    <div class="col-xs-12 padding-15 text-black-light">
        <a routerLink="" class="col-xs-12 push-15-top remove-padding text-white">
            <!-- <span class="fa fa-home push-10-right"></span>--> {{ 'inicio' | translate }}
        </a>
        <a routerLink="pijao" class="col-xs-12 push-15-top remove-padding text-white"> Pijao </a>
        <a routerLink="paquetes" class="col-xs-12 push-15-top remove-padding text-white"> {{ 'paquetes' | translate }} </a>
        <a routerLink="galeria" class="col-xs-12 push-15-top remove-padding text-white"> {{ 'galeria' | translate }} </a>
        <a routerLink="publicaciones" class="col-xs-12 push-15-top remove-padding text-white"> {{ 'publicaciones' | translate }} </a>
    </div>
</div>
<a name="up" class="col-xs-12 hidden"></a>