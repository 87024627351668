import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
 
import { PrincipalComponent } from './components/principal/principal.component'; 
import { SitioComponent } from './components/sitio/sitio.component';  
import { GaleriaComponent } from './components/galeria/galeria.component';  
import { PaquetesComponent } from './components/paquetes/paquetes.component';  
import { PijaoComponent } from './components/pijao/pijao.component';  
import { PublicacionesComponent } from './components/publicaciones/publicaciones.component';  

const routes: Routes = [ 
  {
    path:'',
    component:PrincipalComponent
  },
  {
    path:'sitio/:id',
    component:SitioComponent, 
  } ,
  {
    path:'galeria',
    component:GaleriaComponent
  },
  {
    path:'paquetes',
    component:PaquetesComponent
  },
  {
    path:'pijao',
    component:PijaoComponent
  },
  {
    path:'publicaciones',
    component:PublicacionesComponent
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})], 
  exports: [RouterModule]
})
export class AppRoutingModule { }