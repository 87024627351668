import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore'; 
import { List } from '../models/List'; 
import { Observable } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class ListCommerceService {
  listCollection: AngularFirestoreCollection<List>
  list:  Observable<List[]>; 
  toda_galeria:  Observable<List[]>;
  todos_posts:  Observable<List[]>;
  commerce:  Observable<List[]>; 
  commerceLF:  Observable<List[]>; 
  PlanCity:  Observable<List[]>; 

  constructor(public dbTours: AngularFirestore) { 
    this.list = this.dbTours.collection('comercios').valueChanges(); 
  }
  getList(){
    return this.list;
  } 
  getAllGaleria(){
    this.toda_galeria = this.dbTours.collection('galeria').valueChanges();
    return this.toda_galeria;
  }
  getAllPosts(){
    this.todos_posts = this.dbTours.collection('publicaciones').valueChanges();
    return this.todos_posts;
  }
  getGaleria(c){
    this.commerce = this.dbTours.collection('comercios', ref => ref.where('id_commerce', '==', c)).valueChanges(); 
    return this.commerce;
  }
  getLimitGaleria(limit: number){
    this.commerce = this.dbTours.collection('galeria', ref => ref.limit(limit)).valueChanges(); 
    return this.commerce;
  }
  getCommerceLike(valCom){
    this.commerceLF = this.dbTours.collection('comercios', ref => ref.where('nombre_comercio', 'array-contains-any', valCom)).valueChanges(); 
    return this.commerceLF;
  }
}