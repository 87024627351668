<div class="col-xs-3 col-sm-2 col-md-1 remove-padding pull-right right text-right fixed index50 push-30-bottom bottom"> 
    <img src="assets/img/flor.webp" class="absolute wow zoomIn push-50-top" width="30px" data-wow-delay="0.7s" style="margin-left: -50px;" alt="flor" title="flor">
    <img src="assets/img/flor.webp" class="absolute wow zoomIn push-200-top" width="15px" data-wow-delay="1.3s" style="margin-left: -100px;" alt="flor" title="flor">
    <img src="assets/img/flor.webp" class="absolute wow zoomIn" width="25px" data-wow-delay="1s" style="margin-left: -15%;margin-top:25.8%;" alt="flor" title="flor">
    <img src="assets/img/flor.webp" class="absolute wow zoomIn" width="25px" data-wow-delay="1.8s" style="margin-left: -135px;margin-top:120px;" alt="flor" title="flor"> 
    <a (click)="scroll(aves)"><img src="assets/img/cuarto_paquete.webp" class="col-xs-9 col-md-12 push-10-bottom col-xs-offset-2 col-md-offset-0 col-md-12 animated fadeInUp bigHover" title="aves" alt="aves"></a>
    <a (click)="scroll(casa_solaz)"><img src="assets/img/tercer_paquete.webp" class="col-xs-9 col-md-12 push-10-bottom col-xs-offset-2 col-md-offset-0 col-md-12 animated fadeInUp bigHover" title="casa_solaz" alt="casa_solaz"></a>
    <a (click)="scroll(palma_de_cera)"><img src="assets/img/segundo_paquete.webp" class="col-xs-9 col-md-12 push-10-bottom col-xs-offset-2 col-md-offset-0 col-md-12 animated fadeInUp bigHover" title="palma_de_cera" alt="palma_de_cera"></a>
    <a (click)="scroll(pcc)"><img src="assets/img/quinto_paquete.webp" class="col-xs-9 col-md-12 push-10-bottom col-xs-offset-2 col-md-offset-0 col-md-12 animated fadeInUp bigHover" title="pcc" alt="pcc"></a> 
    <a (click)="scroll(historia_arquitectura)"><img src="assets/img/primer_paquete.webp" class="col-xs-9 col-md-12 push-10-bottom col-xs-offset-2 col-md-offset-0 animated  fadeInUp bigHover" title="historia_arquitectura" alt="historia_arquitectura"></a>
</div>
<section class="col-xs-12 center remove-padding bg_green">
   <div class="col-xs-3 pull-right overflow-hidden absolute right bottom remove-padding">
   </div>
   <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
   <img src="assets/img/flor.webp" class="absolute wow zoomIn push-50-top" width="60px" data-wow-delay="0.7s" style="margin-left: 50px;" alt="flor" title="flor">
   <img src="assets/img/flor.webp" class="absolute wow zoomIn push-200-top" width="30px" data-wow-delay="1.3s" style="margin-left: -220px;" alt="flor" title="flor">
   <img src="assets/img/flor.webp" class="absolute wow zoomIn" width="50px" data-wow-delay="1s" style="margin-left: 20%;margin-top:25.8%;" alt="flor" title="flor">
   <img src="assets/img/flor.webp" class="absolute wow zoomIn" width="50px" data-wow-delay="1.8s" style="margin-left: -225px;margin-top:120px;" alt="flor" title="flor">
   <div class="col-md-8 col-md-offset-2 push-200-top push-200-bottom text-shadow text-white">
      <div class="col-xs-12 remove-padding">
         <a (click)="scroll(aves)"><img src="assets/img/cuarto_paquete.webp" class="col-xs-3 wow rollIn bigHover" title="aves" alt="aves"></a>
         <a (click)="scroll(casa_solaz)"><img src="assets/img/tercer_paquete.webp" class="col-xs-3 wow rollIn bigHover" title="casa_solaz" alt="casa_solaz"></a>
         <a (click)="scroll(palma_de_cera)"><img src="assets/img/segundo_paquete.webp" class="col-xs-3 wow rollIn bigHover" title="palma_de_cera" alt="palma_de_cera"></a>
         <a (click)="scroll(pcc)"><img src="assets/img/quinto_paquete.webp" class="col-xs-3 wow rollIn bigHover" title="pcc" alt="pcc"></a>
      </div>
      <a (click)="scroll(historia_arquitectura)"><img src="assets/img/primer_paquete.webp" class="col-xs-3 wow col-xs-offset-1 rollIn bigHover" title="historia_arquitectura" alt="historia_arquitectura"></a>
      <a (click)="scroll(sexto_paquete)"><img src="assets/img/sexto_paquete.webp" class="col-xs-3 wow rollIn bigHover" title="recorrido_cordillerano" alt="recorrido_cordillerano"></a>
      <a (click)="scroll(septimo_paquete)"><img src="assets/img/septimo_paquete.webp" class="col-xs-3 wow rollIn bigHover" alt="paramo_del_chili" title="paramo_del_chili"></a>
   </div>
</section> 
<section class="col-xs-12 center remove-padding shadow-1 index10 hidden-xs bg_black">
   <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
   <div class="col-md-8 col-md-offset-2 text-shadow text-white padding-30">
       <img src="assets/img/logo_png.webp" class="height-100" alt="logo_Birding_Tours_conservatiON"
            title="Logo Bio Onda" loading="lazy">
   </div>
   <div class="absolute bottom right padding-20 text-white"><h5>Tours Pijao</h5></div>
</section>
<a class="col-xs-12 padding-10 center text-white" #aves style="background: url('../img/bg_black.webp')">
<h2 class="pull-5-top">Aves</h2>
</a>
<section class="col-xs-12">
<div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
<div class="col-md-6">
   <h2 class="Reinata font-s64"><span class="Memories">A</span>ves</h2>
   <hr class="border-black">
   <p>{{ 'text_aves_1' | translate }}</p>
   <p>{{ 'text_aves_2' | translate }} 
   </p>
   <p>
       {{ 'text_aves_3' | translate }}
   </p>
</div>
<div class="col-md-6 text-right">
   <img src="assets/img/cuarto_paquete.webp" class="col-xs-8 col-xs-offset-4 push-50-top" alt="Paquete" title="Paquete">
   <div class="col-xs-12">
      <hr class="col-xs-4 col-xs-offset-6 border-black">
   </div>
   <p class="col-xs-12 col-md-11">
      <b>Más información: </b> 
      <br/>
      <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20del%20paquete%20Aves." target="_blank">
         <span class="fa fa-whatsapp font-s36"></span>
      </a>
   </p>
</div>
</div>
</section>
<a class="col-xs-12 padding-10 center text-white" #casa_solaz style="background: url('../img/bg_green.webp')">
<h2 class="pull-5-top">Caza Solaz</h2>
</a>
<section class="col-xs-12">
<div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
<div class="col-md-6">
   <img src="assets/img/tercer_paquete.webp" class="col-xs-8 push-50-top" alt="Paquete" title="Paquete">
   <div class="col-xs-12">
      <hr class="col-xs-4 border-black">
   </div>
   <p class="col-xs-12 col-md-11">
      <b>Más información: </b> 
      <br/>
      <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20del%20paquete%20Casa%20Solaz." target="_blank">
         <span class="fa fa-whatsapp font-s36"></span>
      </a>
   </p>
</div>
<div class="col-md-6">
   <h2 class="Reinata font-s64"><span class="Memories">C</span>asa Solaz</h2>
   <hr class="border-black">
   <p>{{ 'text_casasolaz_1' | translate }}</p>
</div>
</div>
</section>
<a class="col-xs-12 padding-10 center text-white" #palma_de_cera style="background: url('../img/bg_black.webp')">
<h2 class="pull-5-top">Palma de cera</h2>
</a>
<section class="col-xs-12">
<div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
<div class="col-md-6">
   <h2 class="Reinata font-s64"><span class="Memories">P</span>alma de cera</h2>
   <hr class="border-black">
   <p>{{ 'text_palmacera_1' | translate }} 
   </p>
</div>
<div class="col-md-6 text-right">
   <img src="assets/img/segundo_paquete.webp" class="col-xs-8 col-xs-offset-4 push-50-top" alt="Paquete" title="Paquete">
   <div class="col-xs-12">
      <hr class="col-xs-4 col-xs-offset-6 border-black">
   </div>
   <p class="col-xs-12 col-md-11">
      <b>Más información: </b> 
      <br/>
      <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20del%20paquete%20Casa%20Solaz." target="_blank">
         <span class="fa fa-whatsapp font-s36"></span>
      </a>
   </p>
</div>
</div>
</section>
<a class="col-xs-12 padding-10 center text-white" #pcc style="background: url('../img/bg_green.webp')">
<h2 class="pull-5-top">Paisaje Cultural cafetero</h2>
</a>
<section class="col-xs-12">
<div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
<div class="col-md-6">
   <img src="assets/img/quinto_paquete.webp" class="col-xs-8 push-50-top" alt="Paquete" title="Paquete">
   <div class="col-xs-12">
      <hr class="col-xs-4 border-black">
   </div>
   <p class="col-xs-12 col-md-11">
      <b>Más información: </b> 
      <br/>
      <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Me%20interesa%20saber%20m%C3%A1s%20acerca%20del%20paquete%20Paisaje%20Cultural%20Cafetero." target="_blank">
         <span class="fa fa-whatsapp font-s36"></span>
      </a>
   </p>
</div>
<div class="col-md-6">
   <h2 class="Reinata font-s64"><span class="Memories">P</span>aisaje Cultural cafetero</h2>
   <hr class="border-black">
   <p>{{ 'text_pcc_1' | translate }}
   </p>
</div>
</div>
</section>
<a class="col-xs-12 padding-10 center text-white" #historia_arquitectura style="background: url('../img/bg_green.webp')">
<h2 class="pull-5-top">Historia Arquitectura</h2>
</a>
<section class="col-xs-12">
<div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
    <div class="col-md-6 text-right">
        <img src="assets/img/primer_paquete.webp" class="col-xs-8 push-50-top" alt="Paquete" title="Paquete">
        <div class="col-xs-12">
           <hr class="col-xs-4 col-xs-offset-6 border-black">
        </div>
        <p class="col-xs-12 col-md-11">
           <b>Más información: </b> 
           <br/>
           <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Me%20interesa%20saber%20m%C3%A1s%20acerca%20del%20paquete%20Historia%20Arquitectura." target="_blank">
              <span class="fa fa-whatsapp font-s36"></span>
           </a>
        </p>
    </div>
    <div class="col-md-6">
        <h2 class="Reinata font-s64"><span class="Memories">H</span>istoria, arquitectura, tejados y colores</h2>
        <hr class="border-black">
        <p>{{ 'text_historiaArq_1' | translate }}
        </p>
        <p>{{ 'text_historiaArq_2' | translate }}
        </p>
    </div>
</div>
</section>


<a class="col-xs-12 padding-10 center text-white" #septimo_paquete style="background: url('../img/bg_black.webp')">
<h2 class="pull-5-top">P&aacute;ramo de chil&iacute;</h2>
</a>
<section class="col-xs-12">
<div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
<div class="col-md-6">
   <h2 class="Reinata font-s64"><span class="Memories">P</span>&aacute;ramo de Chil&iacute;</h2>
   <hr class="border-black">
   <p>{{ 'text_paramo_1' | translate }} 
   </p>
</div>
<div class="col-md-6">
   <img src="assets/img/septimo_paquete.webp" class="col-xs-8 col-xs-offset-4 push-50-top" alt="Paquete" title="Paquete">
   <div class="col-xs-12">
      <hr class="col-xs-4 border-black">
   </div>
   <p class="col-xs-12 col-md-11">
      <b>Más información: </b> 
      <br/>
      <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Me%20interesa%20saber%20m%C3%A1s%20acerca%20del%20paquete%20P%C3%A1ramo%20de%20chil%C3%AD." target="_blank">
         <span class="fa fa-whatsapp font-s36"></span>
      </a>
   </p>
</div>
</div>
</section>
<a class="col-xs-12 padding-10 center text-white" #sexto_paquete style="background: url('../img/bg_black.webp')">
<h2 class="pull-5-top">Recorrido Cordillerano</h2>
</a>
<section class="col-xs-12">
   <div class="col-md-10 col-md-offset-1 push-30-top push-50-bottom">
      <div class="col-md-6">
         <h2 class="Reinata font-s64"><span class="Memories">R</span>ecorrido cordillerano</h2>
         <hr class="border-black">
         <p>
            {{ 'text_recorrido_1' | translate }} 
         </p>
      </div>
      <div class="col-md-6 text-right">
         <img src="assets/img/sexto_paquete.webp" class="col-xs-8 col-xs-offset-4 push-50-top" alt="Paquete" title="Paquete">
         <div class="col-xs-12">
            <hr class="col-xs-4 col-xs-offset-6 border-black">
         </div>
         <p class="col-xs-12 col-md-11">
            <b>Más información: </b> 
            <br/>
            <a href="https://api.whatsapp.com/send?phone=+573108491829&text=%C2%A1Hola!%20Me%20interesa%20saber%20m%C3%A1s%20acerca%20del%20paquete%20Recorrido%20Cordillerano" target="_blank">
               <span class="fa fa-whatsapp font-s36"></span>
            </a>
         </p>
      </div>
   </div>
</section>