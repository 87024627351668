import { Component, OnInit } from '@angular/core';
import { ListCommerceService } from 'src/app/services/list-commerce.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  images:any;
  questions = [
    {
      question: "¿Qué es el aviturismo?",
      answer: "Es una actividad que consiste en observar y apreciar las aves en su hábitat natural, con fines recreativos, educativos o de investigación."
    },
    {
      question: "¿Qué es Bio Onda Pijao?",
      answer: "Es un emprendimiento local que ofrece recorridos para observación de aves en el municipio de Pijao Quindío, con un enfoque en la conservación de la naturaleza y el bienestar de los visitantes y la comunidad."
    },
    {
      question: "¿Qué rutas ofrece Bio Onda Pijao?",
      answer: "Ofrece rutas con diferentes perfiles que se acomodan a los requerimientos de una amplia variedad de avistadores, desde principiantes hasta expertos, y que abarcan diversos paisajes y ecosistemas, desde valles hasta páramos."
    },
    {
      question: "¿Qué especies de aves se pueden observar en Pijao Quindío?",
      answer: "Se pueden observar más de 300 especies de aves, entre las que se destacan el gallito de roca andino, el colibrí orejivioleta colilargo, el quetzal dorado, el tucán andino, el loro orejiamarillo y el cóndor andino."
    },
    {
      question: "¿Qué otros atractivos tiene Pijao Quindío?",
      answer: "Pijao es el primer municipio de Colombia y América Latina incluido en la red mundial “Cittáslow”, que rescata y conserva la arquitectura, la cultura, el paisaje y las tradiciones locales. También forma parte del patrimonio de la humanidad “Paisaje Cultural Cafetero Colombiano”, con prácticas productivas de cafés especiales."
    },
    {
      question: "¿Cómo puedo reservar un recorrido con Bio Onda Pijao?",
      answer: "Puedes contactarlos a través de cualquiera de sus números de teléfono (+57)3108491829 y (+57)3166208597. También puedes visitar su oficina en la calle 11 # 5-10 en Pijao Quindío."
    },
    {
      question: "¿Qué medidas de bioseguridad se aplican en los recorridos?",
      answer: "Se aplican las medidas establecidas por el Ministerio de Salud y Protección Social de Colombia, tales como el uso obligatorio de tapabocas, el distanciamiento físico, el lavado frecuente de manos y la desinfección de equipos. Además, se limita el número de participantes por recorrido y se evitan las aglomeraciones."
    }
];


  constructor(public listImg: ListCommerceService) { }

  ngOnInit(): void {
    this.listImg.getLimitGaleria(4).subscribe(listImages =>{ 
      this.images = listImages; 
    }); 
    //this.startQuiz();
  } 
// función para realizar el cuestionario
startQuiz(){
  let score = 0; // inicializa el puntaje del usuario
  for (let i = 0; i < this.questions.length; i++) {
    const userAnswer = prompt(this.questions[i].question); // muestra la pregunta y guarda la respuesta del usuario en una variable
    if (userAnswer.toLowerCase() === this.questions[i].answer.toLowerCase()) { // verifica si la respuesta del usuario es correcta (ignora mayúsculas y minúsculas)
      alert("¡Respuesta correcta!"); // muestra un mensaje de respuesta correcta
      score++; // incrementa el puntaje del usuario
    } else {
      alert("Respuesta incorrecta. La respuesta correcta es: " + this.questions[i].answer); // muestra un mensaje de respuesta incorrecta y la respuesta correcta
    }
  }
  alert("Fin del cuestionario. Tu puntaje es: " + score + " de " + this.questions.length); // muestra el puntaje final del usuario
}

} 