<div class="col-xs-12 remove-padding push-50-top">
    <div class="col-xs-12 height-400 remove-padding" style="background-image: url('{{commerce[0].imagen2_comercio}}');background-size:cover;"> 
        <div class="filter bg-primary"></div>
        <div class="col-xs-12 col-md-5 push-20-top text-white">
            <div class="col-xs-12">
                <h1 style="font-size:48px;">{{commerce[0].nombre_comercio}}</h1> 
                <h5><a href="facebook.com/{{commerce[0].facebook_comercio}}">@{{commerce[0].facebook_comercio}}</a></h5>
            </div>
            <div class="col-xs-12 push-40-top">
                <div class="col-xs-3 height-100 overflow-hidden radius-5 shadow-5 remove-padding push-10-left" *ngIf="commerce[0].imagen1Perfil_comercio.length >= 10">
                    <img src="{{commerce[0].imagen1Perfil_comercio}}" alt="Imagen perfil 1" class="col-xs-12 remove-padding">
                </div>
                <div class="col-xs-3 height-100 overflow-hidden radius-5 shadow-5 remove-padding push-10-left" *ngIf="commerce[0].imagen2Perfil_comercio.length >= 10">
                    <img src="{{commerce[0].imagen2Perfil_comercio}}" alt="Imagen perfil 2" class="col-xs-12 remove-padding">
                </div>
                <div class="col-xs-3 height-100 overflow-hidden radius-5 shadow-5 remove-padding push-10-left" *ngIf="commerce[0].imagen3Perfil_comercio.length >= 10">
                    <img src="{{commerce[0].imagen3Perfil_comercio}}" alt="Imagen perfil 3" class="col-xs-12 remove-padding"> 
                </div>
            </div>
        </div>
        <!--
        <div class="col-xs-12 col-md-6 padding-20 push-20-top">
            <div class="col-xs-12 bg-white radius-5 shadow-3-light center padding-20">
                <h3>Reservar</h3>
                <hr>
                <div class="col-xs-12 col-md-6">
                    <button class="btn-material col-xs-12">Desde el día</button>
                </div>
                <div class="col-xs-12 col-md-6">
                    <button class="btn-material col-xs-12">Hasta el día</button>
                </div>
                <div class="col-xs-12 center push-20-top">
                    <div class="col-xs-4"><div class="bg-primary circle circle-xs shadow-3-light padding-5">-</div></div>
                    <div class="col-xs-4">Habitaciones ##</div>
                    <div class="col-xs-4"><div class="bg-primary circle circle-xs shadow-3-light padding-5 pull-right">+</div></div>
                </div>
            </div>
        </div>
        -->
    </div>
</div>
<!--
<section class="col-xs-12 padding-20">
    <h2 class="padding-30">Videos</h2>
    <div class="col-xs-12 remove-padding"> 
        <div class="scroll-horizontal height-200">
            <iframe class="padding-20" width="400" height="315" src="https://www.youtube.com/embed/ZFWC4SiZBao?start=119" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe class="padding-20" width="400" height="315" src="https://www.youtube.com/embed/ZFWC4SiZBao?start=119" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe class="padding-20" width="400" height="315" src="https://www.youtube.com/embed/ZFWC4SiZBao?start=119" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe class="padding-20" width="400" height="315" src="https://www.youtube.com/embed/ZFWC4SiZBao?start=119" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe class="padding-20" width="400" height="315" src="https://www.youtube.com/embed/ZFWC4SiZBao?start=119" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</section>
-->
<section class="col-xs-12 padding-20">
    <h2 class="padding-30">Acerca de {{commerce[0].nombre_comercio}}</h2>
    <div class="col-xs-12 col-sm-6">
        {{commerce[0].descripcion_comercio}}
    </div>
    <div class="col-xs-12 col-sm-6">
        <h4>Servicios</h4>
        <br>
        <img src="https://st8.cannypic.com/thumbs/15/159677_632_canny_pic.webp" alt="" class="col-xs-12">
    </div> 
</section> 
<section class="col-xs-12 padding-50">
    <iframe width="400" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=Calle%2011%2011-11,%20Armenia+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>     
</section>