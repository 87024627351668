import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module'; 
import { PrincipalComponent } from './components/principal/principal.component';
import { HeaderComponent } from './layouts/header/header.component'; 
 
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';  
import { FooterComponent } from './layouts/footer/footer.component';  
import { PublicacionesComponent } from './components/publicaciones/publicaciones.component';
import { PublicacionComponent } from './components/publicaciones/publicacion/publicacion.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { PaquetesComponent } from './components/paquetes/paquetes.component';
import { PijaoComponent } from './components/pijao/pijao.component';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { ListCommerceService } from './services/list-commerce.service'; 

//TRANSLATE
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { APP_BASE_HREF } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent, 
    PrincipalComponent,
    HeaderComponent, 
    FooterComponent,  
    PublicacionesComponent,
    GaleriaComponent,
    PaquetesComponent,
    PijaoComponent,
    PublicacionComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'angularrfs'),
    AngularFirestoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  })
  ], 
  bootstrap: [AppComponent],
  providers: [ListCommerceService, { provide: LocationStrategy, useClass: HashLocationStrategy }, {provide: APP_BASE_HREF, useValue: '/'}]
})
export class AppModule { }
 