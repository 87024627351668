import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-pijao',
  templateUrl: './pijao.component.html',
  styleUrls: ['./pijao.component.css']
})
export class PijaoComponent implements OnInit {

  constructor(private translate: TranslateService) {
  }
  ngOnInit(): void {
    if(sessionStorage.getItem("language") == null){
      sessionStorage.setItem("language", "es");
    } 
    this.translate.setDefaultLang(sessionStorage.getItem("language"));
    this.translate.use(sessionStorage.getItem("language"));
  } 

}
