
<section class="col-xs-12 center remove-padding"
         style="background: url('../../../assets/img/banner_1.webp') no-repeat;background-size: cover;">
    <div class="col-xs-3 pull-right overflow-hidden absolute right bottom remove-padding">
        <img src="assets/img/bird_1.webp" class="col-xs-12 remove-padding wow slideInUp" title="Ave by Bio Onda" alt="bird_Birding_Tours_conservatiON">
    </div>
    <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
    <div class="col-md-8 col-md-offset-2 push-100-top push-100-bottom text-shadow text-white">
        <img src="../../../assets/img/logo_png.webp" class="height-150" alt="logo_Birding_Tours_conservatiON" title="Logo Bio Onda">
        <h3>Publicaciones</h3>
    </div>
</section>
<div class=" col-xs-12 bg_green p-0-l p-50-b" id="p5">
    <div class="col-xs-12 heightx100 absolute opacity-3 bg-black"></div>

    <div class="col-md-10 col-md-offset-1 col-xs-12  push-50-top remove-padding" id="p5">
        <div *ngIf="listPost?.length > 0; else noList">
            <div *ngFor="let post of listPost">  
                <div class="col-xs-12 col-md-4 col-sm-6 push-20-top">
                    <div class="col-xs-12 padding-20 bg-white radius-5 shadow-5 ">
                        <p class="height-150 overflow-hidden">
                            <img src="{{post.bannerUrl_publicacion}}" alt="titulo_galeria" class="col-xs-12 remove-padding"> 
                        </p>  
                        <h4>
                            {{ post.nombre_publicacion }}
                        </h4>
                        <p class="height-50 overflow-hidden">
                            {{ post.descripcion_publicacion }}
                        </p>
                    </div>
                </div>
            </div>
        </div> 
        <ng-template #noList>
            <h2><span class="fa fa-circle font-s64 animated pulse infinte"></span></h2>
        </ng-template>
    </div>
    
    <div class="col-xs-12 col-md-6 col-md-offset-3 center push-100-top push-50-bottom hidden">
        <div class="col-xs-10 col-xs-offset-1 bg-white radius-8 shadow-4 heightx100 absolute remove-padding overflow-hidden">
            <div class="col-xs-12 heightx100 bg_green" style="filter: blur(2px);"></div>
            <div class="col-xs-12 heightx100 absolute opacity-5 bg-white"></div>
        </div>
        <div class="col-xs-6 col-xs-offset-1 padding-20 center absolute text-left hidden-xs"> 
            <h3 class="font-s36 push-20-left" style="color:#567300;">Página <br>en <br>mantenimiento</h3>
        </div>
        <div class="col-xs-12 remove-padding push-30-bottom">
            <img src="assets/img/mantenimiento.webp" class="col-xs-10 col-xs-offset-1 col-md-5 col-md-offset-6 index10 pull-50-top" alt="publicaciones">
            <div class="absolute bottom col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-5 push-20-bottom">
                <img src="assets/img/mantenimiento_2.webp" class="col-xs-6 col-sm-3 bottom rotateLeft pull-left left push-30-left" alt="publicaciones">
                <img src="assets/img/mantenimiento_3.webp" class="col-xs-6 col-sm-3 bottom rotateRight push-20-left" alt="publicaciones">
            </div>
        </div>
        <div class="col-xs-12 hidden-sm hidden-md hidden-lg">
            <h3 style="color:#567300;">Página web en mantenimiento</h3>
        </div>
    </div>
</div>  