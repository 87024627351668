import { Component, OnInit } from '@angular/core';
import { ListCommerceService } from '../../services/list-commerce.service'; 
import { List } from '../../models/List';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {
  list: List[];
  listImages;
  datos;

  constructor(public listGallery: ListCommerceService, private translate: TranslateService){ 
  }   
  
  ngOnInit(): void {
    
    this.listGallery.getAllGaleria().subscribe(listImages =>{
      this.listImages = listImages; 
    }); 
    if(sessionStorage.getItem("language") == null){
      sessionStorage.setItem("language", "es");
    } 
    this.translate.setDefaultLang(sessionStorage.getItem("language"));
    this.translate.use(sessionStorage.getItem("language"));
  }

}
