<app-header></app-header>  
<div class="col-xs-12 remove-padding overflow-hidden">
<router-outlet></router-outlet> 
</div>
<app-footer></app-footer>  
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-159975687-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-159975687-1');
</script>