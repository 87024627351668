
<div class="col-xs-12 remove-padding col-md-8 col-md-offset-2">
    <div class="col-xs-12 push-50-top push-50-bottom">
        <img src="assets/img/piezas_legales/1.webp" alt="piezas_legales 1 Bio Onda" loading="lazy" title="Bio Onda" class="col-xs-12 col-sm-6 col-md-4 remove-padding">
        <img src="assets/img/piezas_legales/2.webp" alt="piezas_legales 2 Bio Onda" loading="lazy" title="Bio Onda" class="col-xs-12 col-sm-6 col-md-4 remove-padding"> 
        <img src="assets/img/piezas_legales/5.webp" alt="piezas_legales 5 Bio Onda" loading="lazy" title="Bio Onda" class="col-xs-12 col-sm-6 col-md-4 remove-padding"> 
        <img src="assets/img/piezas_legales/3.webp" alt="piezas_legales 3 Bio Onda" loading="lazy" title="Bio Onda" class="col-xs-12 col-sm-6 col-md-4 remove-padding"> 
        <img src="assets/img/piezas_legales/6.webp" alt="piezas_legales 6 Bio Onda" loading="lazy" title="Bio Onda" class="col-xs-12 col-sm-6 col-md-4 remove-padding"> 
        <img src="assets/img/piezas_legales/4.webp" alt="piezas_legales 4 Bio Onda" loading="lazy" title="Bio Onda" class="col-xs-12 col-sm-6 col-md-4 remove-padding"> 
    </div>
</div>
<!-- Contacto con mapa -->
<div class="col-xs-12 bg-black text-white">
    <a class="col-xs-12 padding-20" name="contacto"></a>
    <div class="col-md-10 col-md-offset-1 col-xs-12 padding-30">
        <div class="col-md-6 col-xs-12">
            <div class="col-xs-12">
                <h3 class="padding-20-l">{{ 'contacto' | translate }}</h3>
                <hr>
                <div class="col-xs-12 padding-5">
                    <div class="col-xs-4"><b><span class="fa fa-phone"></span> <span class="fa fa-whatsapp"></span> </b>
                    </div>
                    <div class="col-xs-8">(+57) 3108491829</div>
                </div>
                <div class="col-xs-12 padding-5">
                    <div class="col-xs-4"><b><span class="fa fa-phone"></span> <span class="fa fa-whatsapp"></span> </b>
                    </div>
                    <div class="col-xs-8">(+57) 3166208597</div>
                </div>
            </div>
            <div class="col-xs-12">
                <hr>
                <div class="col-xs-12 padding-5">
                    <b>Pijao</b> <b>Quindío</b>
                </div>

            </div>
        </div>
        <div class="col-md-6 col-xs-12"> 
            <iframe class="col-xs-12 height-300 border-0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3978.4066755172394!2d-75.70643418573715!3d4.3344975459321065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38ff51ae90c8bd%3A0xcea351231a9d0638!2sCl.%2011%20%235-10%2C%20Pijao%2C%20Quind%C3%ADo!5e0!3m2!1ses!2sco!4v1611268376143!5m2!1ses!2sco"
                    allowfullscreen></iframe>
        </div>
    </div>
</div>
<div class="col-xs-12 bg-black hidden">
    <form class="col-md-10 col-md-offset-1 col-xs-12 padding-20" method="post" action="#">
        <h3 class="col-xs-12 text-white">Suscribirse a Bio Onda</h3>
        <div class="col-xs-8">
            <input type="text" class="btn-material bg-white-dark text-black col-xs-12 text-left"
                   placeholder="Correo electronico" required>
        </div>
        <div class="col-xs-4">
            <button class="btn-material col-xs-12 bg-green text-white" type="submit">Suscribirse</button>
        </div>
    </form>
</div>
<footer class="col-xs-12 remove-padding center bg-black text-white">
    <div class="col-xs-12 col-md-10 col-md-offset-1 center padding-20">
        <div class="col-sm-4 padding-20">
            <p>
                <img src="assets/img/logo_blanco_birding.webp" alt="LogoBirdingTOurs" title="LogoBirdingTOurs"
                     class="col-xs-12" loading="lazy">
            </p>
            <a href="https://www.instagram.com/casa.solaz.pijao/" class="col-md-4" target="_blank">
                <img src="assets/img/logo%20Casa%20Solaz.webp" class="col-xs-12 remove-padding" title="Logo Casa Solaz"
                     alt="logo_casa_solaz" loading="lazy">
            </a>
            <div class="col-md-4">
                <h3>RNT 59707</h3>
            </div>
            <a class="col-md-4" href="https://www.instagram.com/bio_onda/"
               target="_blank">
                <img src="assets/img/bio_onda.webp" alt="Bio_Onda" title="Bio Onda"
                     class="col-xs-12 remove-padding push-15-top circle bigHover" loading="lazy">
            </a>
        </div>
        <div class="col-sm-4 padding-30">
            <h3> Bio Onda </h3>
            <p class="text-justify">
                {{ 'text_footer' | translate }} 
            </p>
        </div>
        <div class="col-sm-4 padding-20"><h3>{{ 'contacto' | translate }}</h3>
            <p></p>
            <p><b>Email:</b> birdingtourspijao&#64;gmail.com </p>
            <p><b><span class="fa fa-whatsapp"></span><span class="fa fa-phone push-5-left"></span></b> +57 3108491829
            </p>
            <p><b><span class="fa fa-whatsapp"></span><span class="fa fa-phone push-5-left"></span></b> +57 3166208597
            </p>
            <p>{{ 'title_redes_footer' | translate }}</p>
            <p>
                <a href="https://www.facebook.com/BirdingToursConservationPijao" target="_blank"
                   title="facebook/birdingTours"
                   rel="alternate"><span class="fa fa-facebook-square padding-10 font-s24"></span></a>
                <a href="https://www.instagram.com/birdingtourspijao" target="_blank" title="instagram/birdingTours"
                   rel="alternate"><span class="fa fa-instagram padding-10 font-s24"></span></a>
                <a href="https://www.twitter.com/tourspijao" target="_blank" title="twitter/birdingTours"
                   rel="alternate"><img src="./../../../assets/img/xx.png" width="30px" class="twitter-icon"></a>
                <br>
                <a href="https://tourspijao.blogspot.com" target="_blank"><span class="fa fa-external-link"></span>
                    Bio Onda Blog</a>
            </p>
        </div>
    </div>
    <div class="col-xs-12 push-20-bottom">
        <i><b>Bio Onda</b></i> <i> © 2024. All copyright</i>
    </div>
</footer>
<div id="div_notificacion" class="hidden">
</div> 