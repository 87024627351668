import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { List } from 'src/app/models/List';
import { ListCommerceService } from 'src/app/services/list-commerce.service';

@Component({
  selector: 'app-publicacion',
  templateUrl: './publicacion.component.html',
  styleUrls: ['./publicacion.component.css']
})
export class PublicacionComponent implements OnInit {
  list: List[];
  listPost;
  datos;

  constructor(public listPosts: ListCommerceService, private translate: TranslateService){ 
  }   
  
  ngOnInit(): void {
    
    this.listPosts.getAllPosts().subscribe(listPost =>{
      this.listPost = listPost; 
    }); 
    if(sessionStorage.getItem("language") == null){
      sessionStorage.setItem("language", "es");
    } 
    this.translate.setDefaultLang(sessionStorage.getItem("language"));
    this.translate.use(sessionStorage.getItem("language"));
  }

}
