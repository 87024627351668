<section class="col-xs-12 center remove-padding"
    style="background: url('assets/img/banner_3.webp') no-repeat;background-size: cover;">
    <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
    <div class="col-md-8 col-md-offset-2 push-150-top push-100-bottom text-shadow text-white">
        <img src="assets/img/logo_png.webp" class="height-150" alt="logo_Birding_Tours_conservatiON" title="Logo Bio Onda">
    </div>
    <h3 class="col-xs-12 absolute bottom center text-white push-40-bottom">Galeria de imagenes</h3>
</section>
<section class="col-xs-12 padding-50 bg_black">
    <div class="col-md-10 col-md-offset-1 col-xs-12  remove-padding" id="p5">
        <div *ngIf="listImages?.length > 0; else noList">
            <div *ngFor="let image of listImages"> 
                <a data-fancybox="gallery" href="{{image.bannerUrl}}" class="col-xs-6 col-md-4 shadow-5 push-30-top height-200 overflow-hidden">
                    <img src="{{image.bannerUrl}}" alt="titulo_galeria" class="col-xs-12 remove-padding"> 
                </a>
            </div>
        </div> 
        <ng-template #noList>
            <h2><span class="fa fa-circle font-s64 animated pulse infinte"></span></h2>
        </ng-template>
    </div>   
</section>