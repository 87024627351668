import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-paquetes',
  templateUrl: './paquetes.component.html',
  styleUrls: ['./paquetes.component.css']
})
export class PaquetesComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem("language") == null){
      sessionStorage.setItem("language", "es");
    } 
    this.translate.setDefaultLang(sessionStorage.getItem("language"));
    this.translate.use(sessionStorage.getItem("language"));
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }
}
