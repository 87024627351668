
<div class="col-xs-12 heightx100 fixed bg-black"></div>
<section class="col-xs-12  remove-padding">
    <div class="slide-active" id="slide-1">
        <div class="col-xs-12 remove-padding bg-amethyst bg_img bg_img_4 height100vh">
            <div class="col-xs-12 pull-right right heightx100 absolute "
                 style="background: url('../../../assets/img/gradient_3.png') no-repeat;background-size: cover"></div>
            <div class="col-xs-12 heightx100 opacity-8 bg-black absolute hidden-lg hidden-md"></div>
            <div class="col-md-10 col-md-offset-1 height-500 text-white text-shadow text-right remove-padding">
                <div class="col-xs-12 padding-50 push-40-top">
                    <h3 class="font-s64">Bio Onda</h3>
                    <p class="font-s18 col-xs-12 wow fadeInRight col-md-5 col-md-offset-7 hidden-xs hidden-sm"
                       data-wow-delay="0.8s">
                       <span *ngIf="'pijao_banner_description' | translate;else else1">
                            {{ 'pijao_banner_description' | translate }} 
                        </span>
                       <ng-template #else1>
                        Pijao, pueblo del buen vivir, ubicado en el corazón de las montañas al sur del Quindío, de auténticas tradiciones, con un maravilloso patrimonio natural y riqueza biodiversa, alberga el encanto y la magia que invitan a la contemplación, pleno disfrute ya la conservación de cuanto embellece su entorno.
                       </ng-template>
                    </p>
                    <h5 class="hidden-lg hidden-sm">Pijao, pueblo del buen virir.</h5>
                    <div class="col-md-5 col-md-offset-7 center push-20-top">
                        <a routerLink="galeria" class="col-xs-4 wow fadeInUp bigHover" data-wow-delay="0.5s">
                            <span class="fa fa-image font-s36"></span>
                            <hr>
                            <span class="hidden-xs hidden-sm">{{ 'galeria_img' | translate }}</span>
                        </a>
                        <a routerLink="publicaciones" class="col-xs-4 wow fadeInUp bigHover" data-wow-delay="0.9s">
                            <span class="fa fa-edit font-s36"></span>
                            <hr>
                            <span class="hidden-xs hidden-sm">{{ 'publicaciones' | translate }}</span>
                        </a>
                        <a routerLink="paquetes" class="col-xs-4 wow fadeInUp bigHover" data-wow-delay="1.2s">
                            <span class="fa fa-list-alt font-s36"></span>
                            <hr>
                            <span class="hidden-xs hidden-sm">{{ 'paquetes_d_t_p' | translate }}</span>
                        </a>
                        <button class="btn-material bg-white text-black push-30-top" routerLink="pijao">
                            {{ 'mas_acerca_pijao' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 remove-padding text-shadow text-white absolute push-50-top">
            <div class="col-md-1 pushx50 pull-left center" onclick="slide(3, 1);">
                <span class="fa fa-chevron-left font-s48 hidden-xs"></span>
                <span class="fa fa-chevron-left font-s24 hidden-lg hidden-md hidden-sm"></span>
            </div>
            <div class="col-md-1 pushx50 pull-right center" onclick="slide(2, 1);">
                <span class="fa fa-chevron-right font-s48 hidden-xs"></span>
                <span class="fa fa-chevron-right font-s24 hidden-lg hidden-md hidden-sm"></span>
            </div>
        </div>
    </div>
    <!-- SLIDE TWO -->
    <div class="slide" id="slide-2">
        <div class="col-xs-12 remove-padding bg-amethyst bg_img bg_img_2 height100vh">
            <div class="col-xs-12 bg-black heightx100 fixed opacity-3"></div>
            <div class="col-md-10 col-md-offset-1 height-500 padding-20">
                <div class="col-md-6 text-white text-shadow push-30-top col-xs-12">
                    <img src="assets/img/logo_blanco_birding.webp" class="col-xs-8 col-xs-offset-2 wow zoomIn push-50-top"
                         alt="Logo_birdingtours" title="Logo de Bio Onda">
                    <img src="assets/img/flor.webp" title="Flor de Bio Onda" alt="flor_birdingtours"
                         class="absolute wow zoomIn flor_1_index width-45" data-wow-delay="1s">
                    <img src="assets/img/flor.webp" title="Flor de Bio Onda" alt="flor_birdingtours"
                         class="absolute wow zoomIn flor_2_index width-30" data-wow-delay="1.5s">
                    <img src="assets/img/flor.webp" title="Flor de Bio Onda" alt="flor_birdingtours"
                         class="absolute wow zoomIn push-150-top hidden-xs flor_3_index width-60" data-wow-delay="2s">
                    <hr class="col-xs-12 push-30-top hidden-xs wow fadeInLeft" data-wow-delay="0.5s">
                    <p class="font-s18 col-xs-12 center wow fadeInLeft" data-wow-delay="0.8s">
                        {{ 'text_banner_2' | translate }}
                        <span class="Reinata font-s96 col-xs-12 remove-padding pull-50-top center wow zoomInDown hidden-xs hidden-sm"
                              title="El país de las aves" data-wow-delay="2s">{{ 'text2_banner_2' | translate }}</span>.
                        <span class="Memories font-s48 col-xs-12 remove-padding center wow fadeInLeft hidden-md hidden-lg"
                              title="El país de las aves" data-wow-delay="2s">{{ 'text2_banner_2' | translate }}</span>.
                    </p>
                </div>
                <div class="col-md-6">
                </div>
            </div>
        </div>
        <div class="col-xs-12 remove-padding text-shadow text-white absolute push-50-top">
            <div class="col-md-1 pushx50 font-s48 pull-left center" onclick="slide(1, 2);">
                <span class="fa fa-chevron-left font-s48 hidden-xs"></span>
                <span class="fa fa-chevron-left font-s24 hidden-lg hidden-md hidden-sm"></span>
            </div>
            <div class="col-md-1 pushx50 font-s48 pull-right center" onclick="slide(3, 2);">
                <span class="fa fa-chevron-right font-s48 hidden-xs"></span>
                <span class="fa fa-chevron-right font-s24 hidden-lg hidden-md hidden-sm"></span>
            </div>
        </div>
    </div>
    <!-- SLIDE THREE -->
    <div class="slide" id="slide-3">
        <div class="col-xs-12 remove-padding bg-amethyst bg_img bg_img_3 height100vh">
            <div class="col-xs-12 bg-black heightx100 fixed opacity-5"></div>
            <div class="col-md-10 col-md-offset-1 padding-20 center pushx50">
                <div class="col-xs-12 col-md-4 height-100 hidden-xs">
                    <a data-fancybox="gallery" href="img/jeep1.webp">
                        <img src="assets/img/jeep1.webp" class="circle circle-lg wow bounceIn absolute shadow-4-light"
                             data-wow-delay="0.2s" title="mono de Bio Onda Pijao" alt="mono_pijao" loading="lazy">
                    </a>
                    <img src="assets/img/flor.webp" title="flor de Bio Onda Pijao" alt="flor_pijao Pijao"
                         class="absolute wow zoomIn push-50-left flor_4_index width-30" data-wow-delay="1.5s" loading="lazy">
                    <img src="assets/img/flor.webp" title="flor de Bio Onda Pijao" alt="flor_pijao Pijao"
                         class="absolute wow zoomIn push-50-left flor_5_index width-20" data-wow-delay="1.7s" loading="lazy">
                    <a data-fancybox="gallery" href="img/arquitectura.webp">
                        <img src="assets/img/arquitectura.webp" alt="arquitectura_pijao"
                             title="Arquitectura Bio Onda Pijao"
                             class="circle circle-md pull-50-left wow bounceIn shadow-4-light pull-200-top"
                             data-wow-delay="1.2s" loading="lazy">
                    </a>
                    <a data-fancybox="gallery" href="img/bird_2.webp">
                        <img src="assets/img/bird_2.webp" alt="bird_pijao" title="Ave Bio Onda Pijao"
                             class="circle circle-sm  push-150-top push-15-left wow bounceIn shadow-4-light flor_6_index"
                             data-wow-delay="0.7s" loading="lazy">
                    </a>
                </div>
                <div class="col-xs-12 col-md-8 text-white">
                    <h2>Bio Onda Experience</h2>
                    <p><i>Bio Onda</i> {{ 'text_banner_3' | translate }}</p>
                    <hr>
                    <div class="col-xs-4">
                        <span class="fa fa-building font-s48 wow rollIn"></span>
                        <h4 class="hidden-xs">{{ 'arq' | translate }}</h4>
                    </div>
                    <div class="col-xs-4">
                        <span class="fa fa-binoculars font-s48 wow rollIn"></span>
                        <h4 class="hidden-xs"> {{ 'av_monos' | translate }}</h4>
                    </div>
                    <div class="col-xs-4">
                        <span class="fa fa-plus-circle font-s48 wow rollIn"></span>
                        <h4 class="hidden-xs">{{ 'otros' | translate }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 remove-padding text-shadow text-white absolute push-50-top">
            <div class="col-md-1 pushx50 font-s48 pull-left center" onclick="slide(2, 3);">
                <span class="fa fa-chevron-left font-s48 hidden-xs"></span>
                <span class="fa fa-chevron-left font-s24 hidden-lg hidden-md hidden-sm"></span>
            </div>
            <div class="col-md-1 pushx50 font-s48 pull-right center" onclick="slide(1, 3);">
                <span class="fa fa-chevron-right font-s48 hidden-xs"></span>
                <span class="fa fa-chevron-right font-s24 hidden-lg hidden-md hidden-sm"></span>
            </div>
        </div>
    </div> 
</section>
<section class="col-xs-12 center remove-padding shadow-1 index10 hidden-xs bg_black">
    <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
    <div class="col-md-8 col-md-offset-2 text-shadow text-white padding-30">
        <img src="assets/img/logo_png.webp" class="height-100" alt="logo_Birding_Tours_conservatiON"
             title="Logo Bio Onda" loading="lazy">
    </div>
    <div class="absolute bottom right padding-20 text-white"><h5>Tours Pijao</h5></div>
</section>
<section class="col-xs-12 center remove-padding bg_green">
    <div class="col-xs-12 heightx100 absolute opacity-5 bg-black"></div>
    <h2 class="col-xs-12 padding-20 text-white">Paquetes de Bio Onda</h2>
    <div class="col-xs-12">
        <img alt="flor" title="flor Pijao" src="assets/img/flor.webp"
             class="hidden-xs absolute wow zoomIn push-50-top push-50-left width-60" data-wow-delay="0.7s" loading="lazy">
        <img alt="flor" title="flor Pijao" src="assets/img/flor.webp"
             class="hidden-xs absolute wow zoomIn push-200-top flor_7_index width-30" data-wow-delay="1.3s" loading="lazy">
        <img alt="flor" title="flor Pijao" src="assets/img/flor.webp"
             class="hidden-xs absolute wow zoomIn flor_8_index width-50" data-wow-delay="1s" loading="lazy">
        <img alt="flor" title="flor Pijao" src="assets/img/flor.webp"
             class="hidden-xs absolute wow zoomIn flor_9_index width-50" data-wow-delay="1.8s" loading="lazy">
    </div>
    <div class="col-md-8 col-md-offset-2 push-100-bottom text-shadow text-white">
        <div class="col-xs-12 remove-padding">
            <div class="col-xs-12 remove-padding col-md-6">
                <a routerLink="paquetes"><img src="assets/img/cuarto_paquete.webp" class="col-xs-6 wow rollIn bigHover"
                                             title="aves" alt="aves" loading="lazy"></a>
                <a routerLink="paquetes"><img src="assets/img/tercer_paquete.webp" class="col-xs-6 wow rollIn bigHover"
                                                   title="casa_solaz" alt="casa_solaz" loading="lazy"></a>
            </div>
            <a routerLink="paquetes"><img src="assets/img/segundo_paquete.webp"
                                                  class="col-xs-6 col-md-3 wow rollIn bigHover" title="palma_de_cera"
                                                  alt="palma_de_cera" loading="lazy"></a>
            <a routerLink="paquetes"><img src="assets/img/quinto_paquete.webp" class="col-xs-6 col-md-3 wow rollIn bigHover"
                                        title="pcc" alt="pcc" loading="lazy"></a>
        </div>
        <a routerLink="paquetes"><img src="assets/img/primer_paquete.webp"
                                                      class="col-xs-6 col-md-3 wow col-md-offset-1 rollIn bigHover"
                                                      title="historia_arquitectura" alt="historia_arquitectura" loading="lazy"></a>
        <a routerLink="paquetes"><img src="assets/img/sexto_paquete.webp" class="col-xs-6 col-md-3 wow rollIn bigHover"
                                              title="recorrido_cordillerano" alt="recorrido_cordillerano" loading="lazy"></a>
        <a routerLink="paquetes"><img src="assets/img/septimo_paquete.webp"
                                                class="col-xs-6 col-md-3 wow rollIn bigHover" alt="paramo_del_chili"
                                                title="paramo_del_chili" loading="lazy"></a>
    </div>
    <a routerLink="paquetes" class="col-xs-12 center text-white m-b-50"><u><small>{{ 'ver_t_paquetes' | translate }}</small></u></a>
</section>

<section class="col-xs-12 remove-padding bg-white text-black-light">
    <div class="col-xs-12 heightx100 opacity-3 bg_green absolute"></div>
    <div class="col-md-10 col-md-offset-1 font-s18 padding-50">
        <h3 class="center font-s36">Bio Onda</h3>
        <br>
        <div class="col-md-4">
            <p class="col-md-10 col-md-offset-1">
                <img src="assets/img/bird_2.webp"
                     class="img-responsive" title="Ave de Bio Onda"
                     alt="bird_by_birding_tours_conservation" loading="lazy">
            </p>
        </div>
        <div class="text-justify col-md-8">
            <p class="col-md-10 col-md-offset-1 push-30-top remove-padding">
                <i>Bio Onda</i> <i>Pijao</i>, {{ 'content_text_1' | translate }}
            </p>
        </div>
        <div class="col-xs-12 padding-20 center">
            <div class="col-md-3 col-xs-6">
                <span class="fa fa-map-signs font-s64"></span>
                <br>
                <br>
                <h5 class="font-s18"> {{ 'guianza' | translate }} </h5>
            </div>
            <div class="col-md-3 col-xs-6">
                <span class="fa fa-shield font-s64"></span>
                <br>
                <br>
                <h5 class="font-s18"> {{ 'seguro_d_p_a_viajero' | translate }} </h5>
            </div>
            <div class="col-md-3 col-xs-6">
                <span class="fa fa-binoculars font-s64"></span>
                <br>
                <br>
                <h5 class="font-s18"> {{ 'prestamo_bino' | translate }}</h5>
            </div>
            <div class="col-md-3 col-xs-6">
                <span class="fa fa-image font-s64"></span>
                <br>
                <br>
                <h6 class="font-s18">{{ 'registor_fotos' | translate }}</h6>
            </div>
        </div>
    </div>
</section>
<div class="col-xs-12 center remove-padding bg_section_2">
    <div class="col-xs-12 heightx100 opacity-8 bg-black absolute"></div>
    <div class="col-xs-12 padding-20">
        <div *ngFor="let img of images">
            <a data-fancybox="gallery" href="{{img.bannerUrl}}" class="col-xs-12 col-sm-6 col-md-3 shadow-5 push-30-top height-200 overflow-hidden">
                <img src="{{img.bannerUrl}}" alt="titulo_galeria" class="col-xs-12 remove-padding"> 
            </a>
        </div>
    </div>
    <div class="col-xs-12 padding-50">
        <button class="btn-material bg-white shadow-3-light btn-lg text-black" routerLink="galeria"><span
                class="fa fa-image"></span>
            Ver galeria de imagenes
        </button> 
    </div>
</div>
<section class="col-xs-12 remove-padding bg_so">
    <div class="col-xs-12 heightx100 absolute bg_so_xs hidden-sm hidden-md hidden-lg"></div>
    <div class="col-xs-10 text-white heightx100 absolute hidden-xs" style="background: rgb(158,158,158);
background: linear-gradient(270deg, rgba(158,158,158,0) 0%, rgba(37,37,37,0.7651435574229692) 31%, rgba(29,29,29,0.8883928571428571) 34%, rgba(23,23,23,0.9444152661064426) 36%, rgba(17,17,17,0.9808298319327731) 39%, rgba(5,5,5,1) 42%, rgba(0,0,0,1) 100%);">
    </div>

    <div class="col-md-4 col-md-offset-1 col-xs-12 padding-40 text-white"> 
        <h2>{{ 'title_content_2' | translate }}</h2>
        <hr>
        <p>{{ 'text_content_2' | translate }}</p>
        <p id="more_so" class="hidden">{{ 'text2_content_2' | translate }}</p>
        <u class="pointer" onclick="document.getElementById('more_so').className='';">Más acerca</u>
    </div>
</section>
<section class="col-xs-12 remove-padding bg-white-dark overflow-hidden">
    <div class="col-md-10 col-md-offset-1 col-xs-12 remove-padding font-s18 push-20-top">
        <img src="assets/img/bird_3.webp" class="col-xs-4 remove-padding wow slideInUp absolute bottom right opacity-3"
             data-wow-delay="1s" alt="bird_Birding_Tours_conservatiON" title="Ave by Bio Onda Pijao" loading="lazy">
        <div class="col-xs-12 remove-padding padding-30 push-20-bottom">
            <div class="col-sm-6">
                <h2>{{ 'title_content_3' | translate }}</h2>
                <h3>{{ 'subtitle_content_3' | translate }}</h3>
                <h4>{{ 'subtitle2_content_3' | translate }}</h4>
                <hr>
                {{ 'text_content_3' | translate }}
            </div>
            <div class="col-sm-6">
                {{ 'text2_content_3' | translate }}
                <br>
                <br>
                {{ 'text3_content_3' | translate }}
                <hr>
                <a routerLink="pijao">#<b>Pijao</b></a>
                <a routerLink="pijao">#<b>ToursPijao</b></a>
                <a routerLink="pijao">#<b>Tours</b></a>
                <a routerLink="pijao">#<b>Cittáslow</b></a>
                <br>
                <a routerLink="pijao">#<b>Quindío</b></a>
                <a routerLink="pijao">#<b>PijaoQuindio</b></a>
                <a routerLink="pijao">#<b>BirdingTours</b></a>
                <a routerLink="pijao">#<b>BirdingPijao</b></a>
                <br>
                <a routerLink="pijao">#<b>PaisajeCulturalCafetero</b></a>
            </div>
            <a routerLink="pijao" class="col-xs-12 push-20-top">
                <button class="btn-material bg-blue shadow-5">{{ 'btn_content_3' | translate }}
                </button>
            </a>
        </div>
    </div>
</section> 
<section class="preguntas-frecuentes clearfix col-xs-12">	 
    <div class="preguntas-frecuentes-contenedor serif">
        
        <div class="preguntas-frecuentes-encabezado-linea-roja"></div>
        <h1 class="texto-h3">Preguntas Frecuentes</h1>
        
        <div class="accordion css-accordion preguntas">
            <div *ngFor="let q of questions; let index = index">
                <input class="accordion-item-input" type="checkbox" name="accordion" id="item{{index}}" />
                <label for="item{{index}}" class="accordion-item-hd">
                    {{q.question}}
                    <span class="accordion-item-hd-cta">▲</span>
                </label>
                <div class="accordion-item-bd respuesta">
                    <p>
                        {{q.answer}}
                    </p>
                </div>
            </div>
        </div>    
    </div>  
</section>
<section class="col-xs-12 remove-padding bg-asphalt-light bg_img bg_green">
    <div class="col-xs-12 col-md-8 bg-black heightx100 absolute opacity-9 shadow-1-light"></div>
    <div class="col-xs-12 col-md-10 col-md-offset-1">
        <div class="col-md-8 font-s18 text-white padding-20 text-justify"><h5
                class="text-left push-30-top text-shadow h2">{{ 'title_content_4' | translate }}</h5>
            <hr>
            <p class="push-20-top">{{ 'text_content_4' | translate }}</div>
        <div class="col-xs-12 push-50-bottom" id="quienes_somos"> 
        </div>
    </div>
</section> 